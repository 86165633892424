import React from 'react'
const SectionTwo: React.FC<any> = () => {
    return (
        <>
            <div className="table-checkout">
                <div className="container-fluid">
                    <h1>Why Choose KwikCheckout’s One-Click
                        Checkout Over Others?</h1>
                    <div style={{ overflowX: 'auto' }}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Value Proposition</th>
                                    <th className='tblwidth'>Simpl</th>
                                    <th className='tblwidth'>Razorpay</th>
                                    <th className='tblbgcolor'>KwikCheckout by GoKwik</th>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Deepest 100 Million+ D2C shopper network</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png' width={37} /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png' width={37} /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png' width={37} /></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Highest address pre-fill accuracy and fraudulent pin code detection</td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Above Parity</td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Highest & most customised discount use cases</td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Above Parity</td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>Best-in-class RTO intelligence with risk based COD interventions</td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png' width={37} /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/wrong.png' width={37} /></td>
                                    <td className='tblalign'><img src='/assets/images/one-click-kwikcheckout/right.png' width={37} /></td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>
                                        Robust shipping module<br/>
                                        <small>(weight & pincode and payment method specific based)</small>
                                    </td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Above Parity</td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>SSO Login</td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Above Parity</td>
                                </tr>
                                <tr>
                                    <td className='titlepad'>
                                        Loyalty/Gift card/Funded offers/Rewards<br/>
                                        <small>(deeply integrates with Shopify, Quicksilver, Gyftr, and TwidPay gift cards)</small>
                                    </td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Below Parity</td>
                                    <td className='tblalign'>Above Parity</td>
                                </tr>
                                {/* <tr>
                                    <td>Payment Assurance With ZERO Payment Failures</td>
                                    <td className='tblalign'>80-85%</td>
                                    <td className='tblalign'>80-85%</td>
                                    <td className='tblalign'>Up to 100%</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionTwo