import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import './Footer.css'
import { API_BASE_URL } from "@utils/constant";


const Footer = () => {

    const wasCalled = useRef(false);
    const [result, setResult] = useState<any>([])

    async function fetchData(cat = 0, p = 1, q = "") {

        let uri = API_BASE_URL + 'menu/footer'
        if (q != "") {
            const query = new URLSearchParams({ q: q }).toString();
            uri = uri + "?" + query
        }

        fetch(uri).then(
            function (response) {
                if (response.status !== 200) {
                    return;
                }
                response.json().then(function (data) {
                    setResult(data);
                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    useEffect(() => {
        if (wasCalled.current) return;
        wasCalled.current = true;
        fetchData();
    }, [result]);


    return (
        <>
            <footer>
                <div className="container-fluid ">
                    <div className="row">

                        <div className="col-md-12 foot-top pt-4 pb-4">
                            <div className="col-12 col-md-5">
                                <Link to="/">
                                    <img src="/assets/images/logo@2x.png" width="180" height="52" alt="GoKwik" />
                                </Link>
                            </div>


                            <ul className="col-12 col-md-7  social">
                                <li><a href="https://www.facebook.com/GoKwikCo" target="_blank"><i className="fa-brands fa-square-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/gokwik.co" target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/gokwik" target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                                <li><a href="https://twitter.com/gokwik" target="_blank"><i className="fa-brands fa-x-twitter"></i></a></li>
                                <li><a href="https://www.youtube.com/channel/UC7pFLM30cRSFZTMBqq2yF9Q" target="_blank"><i className="fa-brands fa-youtube"></i></a></li>
                            </ul>

                            {/* <div className="col-12 col-md-7 header-social-links">
                                <a href=""><img src="/assets/images/home/icon/fb.png"/></a>
                                <a href=""><img src="/assets/images/home/icon/ig.png"/></a>
                                <a href=""><img src="/assets/images/home/icon/in.png"/></a>
                                <a href=""><img src="/assets/images/home/icon/tw.png"/></a>
                                <a href=""><img src="/assets/images/home/icon/youtube.png"/></a>
                            </div> */}
                        </div>


                        <div className="conatiner">
                            <div className="row">
                                <div className="col-md-5">

                                    <p className="">
                                        Founded in 2020, GoKwik embarked on a mission to democratise the shopping experience and unlock growth for eCommerce brands by boosting conversion rates, revenue growth, safely expanding COD, containing RTO rate and ensuring high ROI on WhatsApp
                                    </p>

                                    <div className="email pt-4">
                                        {/* <img src="/assets/images/home/icon/email.png" alt="Email" /> */}
                                        <span className="css-sprite-email"></span>
                                        <a href="">support@gokwik.co</a>
                                    </div>

                                    <address className="pt-3 addresssize">
                                        {/* <img src="/assets/images/home/icon/address.png" alt="Address" /> */}
                                        <span className="css-sprite-address"></span>
                                        <p>
                                            Registered Address: 84, Raj Vihar CGHS, Plot No. 13, Sector 18, Dwarka, New Delhi, 110078
                                            {/* 2nd Floor, Coworks, Golf Course Rd, Gurugram, Haryana 122001 */}
                                        </p>
                                    </address>
                                    <address>
                                        <p>
                                            CIN No: U72900DL2020PTC373705
                                        </p>
                                    </address>
                                    {/* <a href="https://seal.esecforte.com/certificate_generate.php?certid=rFrp/CPV/2022/TbXjvx-89216" target="_blank" rel="noreferrer"></a> */}
                                    <a href="https://cdn.gokwik.co/v4/images/coc.pdf" target="_blank" rel="noreferrer">
                                        <img src="/assets/images/home/icon/pci.svg" width="106" height="102" className="card-payment pt-3" alt="PCI" />
                                    </a>
                                </div>


                                <div className="col-md-7">
                                    <div className="row">

                                        {result?.map((row: any, index) => (

                                            <div className="col-6 col-md-3 menu" key={index}>
                                                <h3>{row.title}</h3>
                                                <ul>
                                                    {row.submenu?.map((row1: any, index1) => (
                                                        <li key={index1}><Link to={row1.cta}>{row1.title}</Link></li>
                                                    ))}
                                                </ul>
                                            </div>

                                        ))}

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </footer>







            <div className="foot-bottom">
                <div className="container-fluid">
                    <div className="row">
                        <p>Copyright Ⓒ 2023 GoKwik. All Rights Reserved</p>
                    </div>
                </div>
            </div>


        </>
    )
};

export default Footer;
