import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { Link, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { Form, InputGroup, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import BrandForm from "./brand-form";
import CustomerForm from "./customer-form";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import Slider from "react-slick";
import AgencyForm from "./agency-form";
//https://www.gokwik.co/?utm_source=web&utm_medium=cpc&utm_campaign=test&utm_id=101

const SectionOne: React.FC<any> = (props) => {
    const { data } = props;
    const params = new URLSearchParams(window.location.search);
    const slider = useRef(null)

    const [activeForm, setIsFirstForm] = useState('brand');

    const { register, handleSubmit, setValue, } = useForm({});


    const [validated, setValidated] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errors, setErrors] = useState<any>({});


    const onSubmit: SubmitHandler<any> = async (data) => {

    }
    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        fade: false,
        slide: 'div',
        cssEase: 'linear',
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        rtl: false,
    }

    const renderArrows = () => {
        return (
            <div className="right-arrow" >
                <img src="/assets/images/contact/r-arrow.png" onClick={() => slider.current.slickNext()} />
            </div>
        );
    };

    return (
        <>
            <div className="contact-section-one">
                <div className="container-fluid">
                    <div className="row col-12">

                        <div className="col-md-6 leftbox">
                            <p className="small_heading">Contact Us</p>
                            <h1>Let’s</h1>
                            <h1><span>Connect</span></h1>

                        </div>



                    </div>

                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="container-fluid padding-left">

                            <h2 className="f-heading"><span>4000+ brands </span>are unlocking <br /> 10X growth</h2>
                            <div className="grid">
                                <div className="css-xl4503" >
                                    <div className="">
                                        <img src="/assets/images/contact/SNITCH.png" />
                                    </div>
                                </div>
                                <div className="css-y5zeoq">
                                    <div className="">
                                        <img src="/assets/images/contact/ITC.png" width={73} />
                                    </div>
                                </div>
                                <div className="css-xl4503">
                                    <div className="">
                                        <img src="/assets/images/contact/NOISE.png" />
                                    </div>
                                </div>
                                <div className="css-y5zeoq">
                                    <div className="">
                                        <img src="/assets/images/contact/SHOPPERS STOP.png" width={140} />
                                    </div>
                                </div>
                                <div className="css-xl4503">
                                    <div className="">
                                        <img src="/assets/images/contact/LENSKART.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="growth-point">
                                <ul>
                                    <li>100Mn+ shopper network</li>
                                    <li>Upto 40% conversion uplift</li>
                                    <li>85% prefilled addresses</li>
                                    <li>25% higher prepaid orders</li>
                                    <li>1000+ discount use cases</li>
                                    <li>RTO reduction by upto 40%</li>
                                </ul>
                            </div>
                            <div className="check-banner-team">
                                <Slider {...settings} ref={slider}>
                                    <div className="">
                                        <p>“We saw a 40% MoM growth in checkout conversion rate and 23% reduction in RTO”</p>
                                        <div className="banner-team">
                                            <img src="/assets/images/contact/SIDDHARTH.png" width={100} />
                                            <p><span className="check-name">— Siddharth Dungarwal  </span>Founder, SNITCH</p>
                                        </div>
                                        {/* {renderArrows()} */}

                                    </div>
                                    <div className="">
                                        <p>“GoKwik has been an incredible partner. Their platform has helped us solve many shopper pain points throughout the conversion journey and increased our checkout page conversion by 66.6%."</p>
                                        <div className="banner-team">
                                            <img src="/assets/images/contact/ADILQADRI.png" width={100} />
                                            <p><span className="check-name">— Adil Qadri </span>Founder and CEO, AdilQadri</p>
                                        </div>
                                        {/* <div className="left-arrow" >
                                        <img src="/assets/images/contact/r-arrow.png" />
                                    </div> */}
                                    </div>
                                    <div className="">
                                        <p>"KwikChat has truly transformed our customer engagement strategy. We're now resolving 50% more issues, ensuring a higher level of customer satisfaction."</p>
                                        <div className="banner-team">
                                            <img src="/assets/images/contact/Kanika.png" width={100} />
                                            <p><span className="check-name">— Kanika Hirani, Manager </span>Retention & Loyalty, SuperBottoms.</p>
                                        </div>
                                    </div>
                                </Slider>

                                {renderArrows()}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 rightbox">
                        <div className="form-tab">
                            <div onClick={() => setIsFirstForm('brand')} className={activeForm === "brand" ? "item brand active" : "item brand"}>

                                <p>Are You A</p>
                                <h3>Brand?</h3>
                            </div>
                            <div onClick={() => setIsFirstForm('customer')} className={activeForm === "customer" ? "item active" : "item"}>

                                <p>Are You A</p>
                                <h3>Customer?</h3>
                            </div>
                            {/* <div onClick={() => setIsFirstForm('agency')} className={activeForm === "agency" ? "item active" : "item"}>

                                <p>Are You A</p>
                                <h3>Agency?</h3>
                            </div> */}
                        </div>

                        {activeForm === "brand" ? (
                            <Fade direction="right">
                                <BrandForm params={params}></BrandForm>
                            </Fade>
                        ) : (
                            activeForm === "customer" ?
                                <Fade direction="right">
                                    <CustomerForm params={params}></CustomerForm>
                                </Fade>
                                :
                                <Fade direction="right">
                                    <AgencyForm params={params}></AgencyForm>
                                </Fade>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionOne