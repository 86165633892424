import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const SectionFive: React.FC<any> = (props)=> {
    const {data,group,blocks,teams} = props;
    return (
        <>
            <div className="career-section-five">
                <div className="container-fluid">
                    <div className="col-12 inner">
                            <Fade direction="down">
                                <h2 className="section-heading" dangerouslySetInnerHTML={{__html:group?.title}}></h2>
                                <p className="section-desc">{group?.description}</p>
                            </Fade>
                    </div>
                
                    <div className="star-heading">
                        <span>4.5</span>
                        <ul>
                            <li><i className="fa-solid fa-star"></i></li>
                            <li><i className="fa-solid fa-star"></i></li>
                            <li><i className="fa-solid fa-star"></i></li>
                            <li><i className="fa-solid fa-star"></i></li>
                            <li><i className="fa-solid fa-star-half-stroke"></i></li>
                        </ul>
                        
                    </div>

                    <div className="col-12 circles">
                    <Fade>
                        {blocks?.map((row: any, index) => (
                            <div className="circle">
                                <Fade delay={100} cascade damping={0.5}>
                                <Zoom>
                                    <CircularProgressbar strokeWidth={12} value={93} text={row.content+'%'} styles={
                                        buildStyles({"pathColor": "#01B156"})}/>
                                </Zoom>
                                <h4>{row.title}</h4>
                                </Fade>
                            </div>
                        ))}  
                    </Fade>
                    </div>
                    
                    <div className="join-us">
                        <Link to={group?.cta} >{group?.cta_text} <i className="fa-solid fa-chevron-right"></i></Link>
                    </div>
                    

                </div>
                
            </div>
        </>
    )
}
export default SectionFive;